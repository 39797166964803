import { Severity } from '@sentry/types';
import { ISlugLink } from 'shared/models/slug.model';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';

export default defineNuxtRouteMiddleware(async (to) => {
  const parsedProductSlug = to.params.productSlug?.split?.('-');
  const parsedProductId = parsedProductSlug?.[parsedProductSlug.length - 1] || 'product';

  try {
    const productSlugItem = await AuthHelper.fetch<ISlugLink>(`/api/v1/products/${parsedProductId}/slug`, {
      cache: 'no-cache',
    });

    if (productSlugItem?.slug === `/product/${to.params.productSlug}/`) {
      return;
    }

    return navigateTo(productSlugItem?.slug);
  } catch (error) {
    clientSentry.captureException(error, {
      level: Severity.Error,
      tags: {
        nuxtProductMiddleware: true,
      },
      extra: { error },
    });
    throw createError({ statusCode: 404, fatal: true });
  }
});
